import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M9120 12229 c-19 -5 -109 -14 -200 -19 -234 -12 -311 -30 -1425 -332
-60 -16 -132 -36 -160 -43 -121 -32 -586 -158 -645 -174 -36 -10 -99 -28 -140
-39 -41 -11 -133 -36 -205 -56 -132 -36 -376 -102 -430 -116 -16 -4 -131 -35
-255 -69 -124 -34 -247 -68 -275 -76 -27 -7 -126 -34 -220 -60 -181 -50 -226
-62 -345 -94 -41 -11 -142 -38 -225 -61 -146 -41 -270 -74 -330 -90 -16 -4
-88 -24 -160 -44 -71 -20 -164 -45 -205 -56 -285 -78 -363 -101 -590 -174
-466 -150 -638 -248 -910 -516 -168 -165 -275 -312 -374 -512 -70 -142 -130
-306 -172 -468 -8 -30 -21 -77 -28 -105 -17 -64 -34 -148 -51 -256 -8 -48 -18
-104 -24 -125 -5 -22 -15 -113 -21 -204 -5 -91 -14 -219 -20 -285 -5 -66 -12
-205 -15 -310 -4 -104 -10 -221 -15 -260 -15 -121 -21 -279 -11 -294 8 -12 18
-11 63 8 29 13 91 39 138 58 47 19 99 40 115 48 136 59 516 179 715 224 52 12
122 28 155 36 33 8 78 17 100 20 22 3 78 12 125 21 260 45 745 68 960 45 336
-35 369 -40 570 -83 134 -29 339 -85 410 -113 19 -8 54 -19 77 -25 23 -6 45
-15 48 -20 3 -6 14 -10 23 -10 16 0 120 -43 281 -117 40 -19 83 -39 95 -44 11
-5 53 -27 91 -50 39 -22 84 -47 100 -55 35 -18 42 -22 155 -91 47 -29 117 -75
155 -103 39 -27 86 -61 106 -74 111 -75 333 -263 503 -426 115 -111 335 -354
416 -460 14 -18 32 -41 41 -51 8 -10 24 -30 35 -45 10 -14 35 -47 55 -73 40
-52 229 -329 229 -337 0 -2 15 -26 33 -52 36 -53 52 -81 79 -132 10 -19 28
-51 40 -70 11 -19 41 -73 65 -120 25 -47 53 -101 64 -120 35 -66 136 -285 163
-355 16 -38 32 -74 37 -79 5 -6 9 -17 9 -25 0 -9 7 -29 15 -44 8 -15 19 -42
24 -60 6 -18 22 -63 36 -102 49 -134 91 -263 135 -420 12 -44 28 -100 35 -125
8 -25 18 -71 24 -103 6 -32 13 -62 16 -68 3 -5 12 -44 20 -86 8 -43 19 -105
25 -138 39 -209 61 -373 81 -610 16 -176 6 -795 -14 -910 -9 -49 -17 -128 -19
-174 -4 -101 -26 -82 247 -211 111 -53 173 -80 180 -80 4 0 21 -6 38 -14 75
-31 180 -67 325 -111 54 -16 169 -39 282 -57 100 -15 401 -5 485 17 260 68
431 177 672 429 223 233 357 458 480 806 122 348 215 844 242 1300 6 96 13
195 16 220 3 25 12 169 20 320 8 151 19 352 25 445 22 376 41 789 50 1145 6
206 15 512 20 680 12 392 12 884 0 1220 -5 146 -14 391 -20 545 -10 287 -33
628 -51 770 -18 145 -37 274 -44 294 -3 10 -12 59 -20 108 -8 48 -23 129 -34
178 -10 50 -26 122 -34 160 -18 82 -42 180 -62 250 -39 138 -61 207 -86 285
-44 134 -51 153 -65 182 -8 14 -14 32 -14 38 0 7 -9 31 -19 54 -10 23 -31 70
-46 106 -34 83 -105 226 -151 310 -20 36 -42 74 -48 85 -14 26 -89 138 -96
145 -3 3 -32 41 -65 85 -113 152 -300 327 -450 422 -59 38 -188 103 -265 133
-117 46 -208 64 -395 81 -196 17 -257 19 -295 8z"/>
<path d="M3173 7404 c-95 -7 -230 -23 -300 -34 -71 -11 -143 -23 -161 -26 -18
-3 -112 -25 -210 -50 l-177 -44 -90 -90 -89 -89 64 20 c36 11 83 24 105 28
285 63 323 69 595 101 101 12 474 12 585 0 355 -38 647 -118 950 -259 86 -40
265 -139 275 -152 3 -3 39 -28 80 -54 41 -27 77 -52 80 -55 3 -3 48 -38 102
-78 148 -112 360 -320 528 -518 24 -28 112 -145 221 -294 86 -116 269 -415
269 -438 0 -6 3 -12 8 -14 11 -5 152 -284 152 -302 0 -9 5 -16 10 -16 6 0 10
-4 10 -9 0 -5 20 -52 45 -105 25 -53 45 -99 45 -103 0 -5 26 -67 61 -148 74
-169 239 -673 295 -895 8 -36 24 -96 34 -135 112 -420 212 -1061 241 -1550 23
-390 -3 -863 -55 -1015 -8 -22 -12 -40 -11 -40 10 0 162 166 172 188 22 52 42
138 59 252 22 155 30 612 15 830 -19 257 -55 580 -85 758 -8 48 -17 103 -20
122 -3 19 -10 60 -16 90 -6 30 -13 69 -16 85 -15 84 -60 289 -99 445 -67 270
-196 703 -216 724 -5 6 -9 17 -9 25 0 9 -12 47 -26 86 -54 144 -116 301 -135
342 -10 24 -19 45 -19 48 0 23 -235 492 -311 620 -16 28 -35 61 -42 75 -6 14
-17 31 -24 38 -7 7 -25 34 -40 60 -26 45 -63 99 -162 243 -100 143 -228 294
-391 458 -134 135 -167 165 -281 256 -111 88 -103 82 -189 141 -420 288 -915
457 -1407 479 -71 3 -155 7 -188 9 -33 2 -138 -2 -232 -10z"/>
<path d="M2005 4861 c-22 -11 -90 -68 -151 -128 -133 -130 -148 -162 -164
-348 -23 -270 -38 -1471 -20 -1645 30 -298 61 -444 124 -585 8 -16 17 -40 21
-52 3 -13 11 -23 16 -23 5 0 9 -5 9 -11 0 -18 65 -120 120 -190 94 -117 862
-903 965 -987 139 -112 240 -162 328 -162 72 0 103 19 214 131 163 166 184
228 191 561 4 219 -18 585 -53 853 -8 61 -17 151 -20 200 -19 287 -87 763
-132 915 -103 352 -202 499 -584 865 -167 160 -257 244 -374 350 -60 55 -112
102 -115 105 -9 10 -124 95 -153 114 -36 22 -133 56 -161 56 -12 0 -39 -9 -61
-19z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
